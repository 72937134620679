const model = {
  calculation_name: '',
  project_name: '',
  material_name: '', 
  attribute_1: '',
  attribute_2: '',
  density: '',
  is_galvanized: '',
  client_location: '',
};

const params = {
  // product_family: 'Caillebotis électroforgés',
  // calculation_name: 'Nom du calcul',
  // project_name: 'Nom du projet',
  material_name: 'Matière première principale', 
  attribute_1: 'Profils barreaux porteurs (cm)',
  attribute_2: 'Taille de la maille (mm)',
  density: 'Densité (kg/m²)',
  // distance: 'Distance de distribution (km)',
  client_location: 'Code postal du chantier'
}

const form = [
  {
    type: 'text',
    name: 'calculation_name',
    label: 'Nom du calcul',
    placeholder: 'Choisissez le nom du calcul',
    component: 'el-input',
    required: true,
    is_request: false,
    is_disabled: false
  },
  {
    type: 'text',
    name: 'project_name',
    label: 'Nom du projet',
    placeholder: 'Choisissez le nom du projet',
    component: 'el-input',
    required: true,
    is_request: false,
    is_disabled: true  },
  {
    type: 'text',
    name: 'material_name',
    label: 'Quel est la matière première ?',
    placeholder: 'Sélectionnez...',
    component: 'el-select',
    options: [],
    is_request: true,
    is_disabled: true
  },
  {
    type: 'text',
    name: 'attribute_1',
    label: 'Quelle est la taille des profiles ?',
    placeholder: 'Sélectionnez...',
    component: 'el-select',
    options: [],
    is_request: true,
  },
  {
    type: 'text',
    name: 'attribute_2',
    label: 'Quelle est la taille de la maille ?',
    placeholder: 'Sélectionnez...',
    component: 'el-select',
    options: [],
    is_request: true,
    is_disabled: true
  },
  {
    type: 'text',
    name: 'density',
    label: 'Densité surfacique',
    placeholder: 'Sélectionnez...',
    component: 'el-input',
    options: [],
    required: true,
    is_request: true,
    is_disabled: true,
  },
  {
    type: 'text',
    name: 'is_galvanized',
    label: 'Le produit a-t-il été galvanisé à chaud ?',
    placeholder: 'Sélectionnez...',
    component: 'el-select',
    options: [
      {
        label: 'Oui',
        value: 1,
      },
      {
        label: 'Non',
        value: 0,
      },
    ],
    is_request: false,
    is_disabled: true
  },
  {
    type: 'text',
    name: 'client_location',
    label: 'Quel est le code postal du chantier ?',
    placeholder: 'Entrez le code postal',
    component: 'el-input',
    is_request: false,
    is_disabled: true,
    minLength: 5,
    maxLength: 5
  },
];

const rules = {
  calculation_name: [
    {
      type: 'required',
      message: 'Ce champs est requis.',
    },
  ],
  project_name: [
    {
      type: 'required',
      message: 'Ce champs est requis.',
    },
  ],
  material_name: [
    {
      type: 'required',
      message: 'Ce champs est requis.',
    },
  ],
  attribute_1: [
    {
      type: 'required',
      message: 'Ce champs est requis.',
    },
  ],
  attribute_2: [
    {
      type: 'required',
      message: 'Ce champs est requis.',
    },
  ],
  density: [],
  is_galvanized: [
    {
      type: 'required',
      message: 'Ce champs est requis.',
    },
  ],
  client_location: [
    {
      type: 'required',
      message: 'Veuillez fournir votre code postal.',
    },
    {
      type: 'zipcode',
      message: 'Veuillez renseigner un code postal valide.'
    },
    {
      type: 'minLength',
      minLength: 5,
      message: 'Votre code postal ne peut contenir que 5 caractères'
    },
    {
      type: 'minLength',
      maxLength: 5,
      message: 'Votre code postal ne peut contenir que 5 caractères'
    },
  ],
};

export { model, form, rules, params };
