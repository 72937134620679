import api from '@/utils/api';

/**
 * TODO:
 * 1. Send errors from the server
 * 2. Handle exceptions & errors here instead of components and pages
 * 3. Remove console.logs
 */

export default class Database {
    /**
     * -- General CRUD
     */
    static async getAll(collection, params) {
        try {
            const response = await api.get(`/${collection}`, {params: params});
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    static async getOne(collection, id, params) {
        try {
            const response = await api.get(`/${collection}/${id}`, {
                params: params,
            });
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    static async create(collection, item) {
        try {
            const response = await api.post(`/${collection}`, item);
            console.log(response);
            return {
                data: response.data,
                status: response.status,
            };
        } catch (err) {
            console.log(err);
        }
    }

    static async update(collection, id, item) {
        try {
            const response = await api.patch(`/${collection}/${id}`, item);
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    static async delete(collection, id) {
        try {
            const {status} = await api.delete(`/${collection}/${id}`);
            return status;
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * -- Exports
     */

    static async export(item) {
        try {
            return api.post('/exports', item);
        } catch (err) {
            console.log(err);
        }
    }

    static async download(id) {
        try {
            return api.get(`/exports/${id}`, {responseType: 'blob'})
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * -- Users
     */

    static async register(user) {
        try {
            const {status} = await api.post('/users', user);
            console.log(status);
            return status;
        } catch (err) {
            console.log(err);
        }
    }

    static async updateUser(user) {
        try {
            const {data, status} = await api.patch('/users/current', user);

            if (status === 200) {
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(data.user));
            }

            return status;
        } catch (err) {
            console.log(err);
        }
    }

    static async getToken(token) {
        try {
            const {data, status} = await api.get(`/tokens/${token}`);

            return {data, status};
        } catch (err) {
            console.log(err);
        }
    }
}
