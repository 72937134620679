<template>
    <div class="legal-notice">
        <h1>Mentions légales</h1>
        <p class="date">En vigueur au 11/10/2024</p>

        <p>
            Conformément aux dispositions de la loi n°2004-575 du 21 juin 2004 pour la Confiance en
            l’économie numérique, il est porté à la connaissance des utilisateurs et visiteurs,
            ci-après l' "Utilisateur", du site <a
                href="https://staging-meiser.ailoop.app">https://staging-meiser.ailoop.app</a>,
            ci-après le "Site", les présentes mentions légales.
        </p>

        <p>
            La connexion et la navigation sur le Site par l’Utilisateur implique acceptation intégrale
            et sans réserve des présentes mentions légales. Ces dernières sont accessibles sur le Site
            à la rubrique "Mentions légales".
        </p>

        <h2>EDITION DU SITE</h2>
        <p>
            L'édition du Site est assurée par la société Ailoop, SAS au capital de 10000 euros,
            immatriculée au Registre du Commerce et des Sociétés de 928686377 RCS Lille Métropole
            sous le numéro 928686377 dont le siège social est situé au 254 RUE DU BOURG 59130 LAMBERSART,
        </p>

        <ul>
            <li>Numéro de téléphone : <a href="tel:0670148745">+33 6 70 14 87 45</a></li>
            <li>Adresse e-mail : <a href="mailto:gszablewski@ailoop.io">gszablewski@ailoop.io</a></li>
            <li>N° de TVA intracommunautaire : FR20928686377</li>
            <li>Directeur de la publication : Guillaume SZABLEWSKI</li>
        </ul>

        <h2>HEBERGEUR</h2>
        <p>
            L'hébergeur du Site est la société o2switch, dont le siège social est situé au CHE DES PARDIAUX 63000
            CLERMONT FERRAND.
            Le numéro de téléphone de l'hébergeur est le 0444446040.
        </p>

        <h2>ACCES AU SITE</h2>
        <p>
            Le Site est normalement accessible, à tout moment, à l'Utilisateur. Toutefois, l'Editeur
            pourra, à tout moment, suspendre, limiter ou interrompre le Site afin de procéder,
            notamment, à des mises à jour ou des modifications de son contenu. L'Editeur ne pourra en
            aucun cas être tenu responsable des conséquences éventuelles de cette indisponibilité sur
            les activités de l'Utilisateur.
        </p>

        <h2>COLLECTE DES DONNEES</h2>
        <p>
            Le Site assure à l'Utilisateur une collecte et un traitement des données personnelles
            dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative
            à l'informatique, aux fichiers et aux libertés et dans le respect de la règlementation
            applicable en matière de traitement des données à caractère personnel conformément au
            règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (ci-après,
            ensemble, la "Règlementation applicable en matière de protection des Données à caractère
            personnel").
        </p>

        <p>
            En vertu de la Règlementation applicable en matière de protection des Données à caractère
            personnel, l'Utilisateur dispose d'un droit d'accès, de rectification, de suppression et
            d'opposition de ses données personnelles. L'Utilisateur peut exercer ce droit :
        </p>

        <ul>
            <li>Par mail à l'adresse email <a href="mailto:info@ailoop.io">info@ailoop.io</a></li>
            <li>Depuis son espace personnel</li>
        </ul>

        <p>
            Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou
            partie du Site, sans autorisation expresse de l’Editeur est prohibée et pourra entraîner
            des actions et poursuites judiciaires telles que prévues par la règlementation en vigueur.
        </p>
    </div>
</template>

<style scoped>
.legal-notice {
    padding: 2rem;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.legal-notice h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.legal-notice h2 {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.legal-notice p {
    margin-bottom: 1rem;
}

.legal-notice ul {
    list-style-type: none;
    padding: 0;
}

.legal-notice ul li {
    margin-bottom: 0.5rem;
}

.date {
    font-style: italic;
    margin-bottom: 2rem;
}
</style>