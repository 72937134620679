<template>
  <app-container
      v-if="!isTextLoading"
      v-loading="isLoading"
      colWidth="col-12 col-lg-10"
      icon="file_save"
  >
    <template #back>
      <app-back
          :routeName="texts.back_link.route"
          :text="texts.back_link.text"
          colWidth="col-12 col-lg-10"
      />
    </template>
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="row results-row">
        <div class="col-12 col-lg-6 mb-4">
          <div class="results-card">
            <div class="results-card__header">
              <p>{{ texts.params.title }}</p>
            </div>
            <div class="results-card__body text-start">
              <p v-for="item in summary" :key="item.key">
                <span class="text-bold">{{ item.label }} : </span
                >{{ item.value }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <div class="results-card">
            <div class="results-card__header">
              <p>{{ texts.exports.title }}</p>
            </div>
            <div class="results-card__body">
              <div class="row">
                <div
                    v-for="exportType in texts.exports.types"
                    :key="exportType.type"
                    class="col-4"
                >
                  <img :src="require(`@/assets/img/${exportType.img}`)"/>
                  <p class="export-link" @click="handleExport(exportType.type)">
                    {{ exportType.label }}
                  </p>
                </div>
                <div class="col-12 text-start mt-2">
                  <p>
                    {{ texts.exports.comment }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-container>
</template>

<script>
import useTexts from '@/mixins/useTexts';
import Configurator from '@/utils/services/Configurator';
import Database from '@/utils/services/Database';
import Download from "@/utils/services/Download";

/**
 * TODO:
 * - Add units to params
 * - Format params on the backend
 * - Add loader
 */

export default {
  name: 'Step3Page',
  mixins: [useTexts],
  data() {
    return {
      page: 'configurator_3',
      summary: [],
      isLoading: false,
    };
  },
  async created() {
    this.isLoading = true;
    const product_family = JSON.parse(localStorage.getItem('product_family'));
    const answers = JSON.parse(localStorage.getItem('answers'));
    await this.formatParams(product_family, answers);
    this.isLoading = false;
  },
  methods: {
    async formatParams(product_family, answers) {
      this.summary = await Configurator.getParams(answers, product_family);
    },
    async handleExport(type) {
      const product = JSON.parse(localStorage.getItem('answers'));
      const {data} = await Database.export({file_type: type, report_id: product.id});

      await Download.downloadFile(data)
    },
  },
};
</script>

<style lang="scss">
.results-row {
  padding: 2rem 0;

  .results-card {
    height: 100%;
    background-color: $light-grey;
    border-radius: 1rem;

    &__header {
      background-color: $dark;
      border-radius: 1rem 1rem 0 0;
      padding: 1rem;
      color: $light;

      p {
        margin-bottom: 0;
        font-size: 18px;
      }
    }

    &__body {
      padding: 1.5rem;

      .export-link {
        text-decoration: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
