import Database from "@/utils/services/Database";

export default class Download {
    static async downloadFile(data) {
        try {
            // Add a delay to ensure the file is ready
            await new Promise(resolve => setTimeout(resolve, 500));

            const response = await Database.download(data.id);

            // Create a Blob from the response data
            const blob = new Blob([response.data], {type: response.headers['content-type']});

            // Create a URL for the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element and trigger the download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.fileName); // Set the file name
            document.body.appendChild(link);
            link.click();

            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(error)
        }
    }
}